var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.FOOTER_WHO")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-xl-5 px-1 quines-main" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-xl-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoHeader) + " " + _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoSubheader) +
                      " " +
                      _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "h-100 pt-5 ps-md-3 pe-0 spainhn-text d-flex flex-column me-md-5",
                staticStyle: { "border-radius": "10px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 espacio_ant espacio_desp centradomov",
                  },
                  [_c("h1", [_vm._v("¿Quiénes Somos?")])]
                ),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("div", { staticClass: "text-blog text-gral" }, [
                    _c("p", [_vm._v("Estimados amigos,")]),
                    _c("p", [
                      _vm._v(
                        "Spain Heritage Network es la plataforma tecnológica de la asociación y club de producto turístico de castillos y palacios de España. Este club de producto de turismo cultural pretende reunir en una misma red a los castillos y palacios más importantes de España tanto desde el punto de vista histórico como arquitectónico para contribuir a su puesta en valor y para su difusión y disfrute por parte de todos."
                      ),
                    ]),
                    _c("h3", [
                      _vm._v(
                        "Los objetivos del Club de Producto Turístico se pueden resumir en cinco puntos:"
                      ),
                    ]),
                    _c("ul", { staticClass: "text-blog-list" }, [
                      _c("li", [
                        _vm._v(
                          "Impulsar el sector turístico cultural de castillos y palacios de España "
                        ),
                        _c("strong", [
                          _c("u", [
                            _vm._v(
                              "con el apoyo de la Secretaría de Estado de Turismo y Turespaña"
                            ),
                          ]),
                        ]),
                        _vm._v(
                          " a través de distintas iniciativas y convenios, y también con el apoyo de las CCAA involucradas."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("Constituir desde el Club de Producto la "),
                        _c("strong", [
                          _c("u", [
                            _vm._v("“Red de Castillos y Palacios de España”"),
                          ]),
                        ]),
                        _vm._v(
                          " con lo mejor de nuestro rico patrimonio histórico, empezando por el castellológico y de palacios, y dotándolo de un nivel de calidad óptimo según los requisitos del club de producto Y "
                        ),
                        _c("strong", [_vm._v("SEGMENTADO EN RUTAS")]),
                        _vm._v(" en la medida de lo posible."),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Enseñar y entretener dando a conocer la historia de España a través de su rico patrimonio histórico y desde cada uno de sus castillos, fortalezas y palacios más emblemáticos distribuidos por toda la geografía española."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Distribuir, comunicar y diseñar las estrategias de marketing necesarias para convertir la Red Nacional de Castillos y Palacios en "
                        ),
                        _c("u", [_vm._v("un referente de la MARCA ESPAÑA")]),
                        _vm._v(" dándola a conocer por todo el mundo."),
                      ]),
                      _c("li", [
                        _vm._v("Cohesionar en una "),
                        _c("strong", [
                          _vm._v(
                            "plataforma tecnológica www.spainheritagenetwork.com/com"
                          ),
                        ]),
                        _vm._v(
                          ", el producto turístico de castillos y palacios para facilitar su distribución a turoperadores, agencias de viaje, plataformas de distribución y al cliente final facilitándole una herramienta innovadora para poder acceder a nuestros castillos y palacios más emblemáticos."
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Partimos de castillos y palacios con al menos un cierto nivel de gestión activa (visitables, con experiencias turísticas variadas, de gestión pública o privada) y con un claro atractivo. Los castillos y palacios son un recurso con una identidad clara ligada a la historia de nuestra nación y por tanto a la MARCA ESPAÑA, y con un gran potencial interpretativo para diferenciar cada castillo o palacio y cada destino con un relato propio y atractivo para la demanda."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Si conoce algún castillo o palacio de España que pueda cumplir con las condiciones del club de producto y todavía no está en la red díganos cuál a través del correo de contacto y nos pondremos en contacto con él."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Muchas gracias por su visita y esperamos que disfrute de la red de castillos y palacios de España."
                      ),
                    ]),
                    _c("p", [
                      _vm._v("Para más información "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "mailto:info@spainheritagenetwork.com",
                          },
                        },
                        [_c("u", [_vm._v("info@spainheritagenetwork.com")])]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }