<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_WHO')}} </p>
            </div>
        </div>
        <div class="container-fluid px-xl-5 px-1 quines-main">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-xl-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">     
                        <h5 class="mb-0"><strong><small>{{tipoHeader}} {{monumentoNombre}}</small></strong></h5>
                        <p class="mb-0"><strong><small>{{tipoSubheader}} {{monumentoNombre}}</small></strong></p>

                        <div class="h-100 pt-5 ps-md-3 pe-0 spainhn-text d-flex flex-column me-md-5" style="border-radius:10px;">
                            <div class="col-xs-12 espacio_ant espacio_desp centradomov">
                              <h1>&iquest;Qui&eacute;nes Somos?</h1>
                              </div>
                              <div class="col-xs-12">
                              <div class="text-blog text-gral">
                              <p>Estimados amigos,</p>
                              <p>Spain Heritage Network es la plataforma tecnol&oacute;gica de la asociaci&oacute;n y club de producto tur&iacute;stico de castillos y palacios de Espa&ntilde;a. Este club de producto de turismo cultural pretende reunir en una misma red a los castillos y palacios m&aacute;s importantes de Espa&ntilde;a tanto desde el punto de vista hist&oacute;rico como arquitect&oacute;nico para contribuir a su puesta en valor y para su difusi&oacute;n y disfrute por parte de todos.</p>
                              <h3>Los objetivos del Club de Producto Tur&iacute;stico se pueden resumir en cinco puntos:</h3>
                              <ul class="text-blog-list">
                              <li>Impulsar el sector tur&iacute;stico cultural de castillos y palacios de Espa&ntilde;a&nbsp;<strong><u>con el apoyo de la Secretar&iacute;a de Estado de Turismo y Turespa&ntilde;a</u></strong>&nbsp;a trav&eacute;s de distintas iniciativas y convenios, y tambi&eacute;n con el apoyo de las CCAA involucradas.</li>
                              <li>Constituir desde el Club de Producto la&nbsp;<strong><u>&ldquo;Red de Castillos y Palacios de Espa&ntilde;a&rdquo;</u></strong>&nbsp;con lo mejor de nuestro rico patrimonio hist&oacute;rico, empezando por el castellol&oacute;gico y de palacios, y dot&aacute;ndolo de un nivel de calidad &oacute;ptimo seg&uacute;n los requisitos del club de producto Y&nbsp;<strong>SEGMENTADO EN RUTAS</strong>&nbsp;en la medida de lo posible.</li>
                              <li>Ense&ntilde;ar y entretener dando a conocer la historia de Espa&ntilde;a a trav&eacute;s de su rico patrimonio hist&oacute;rico y desde cada uno de sus castillos, fortalezas y palacios m&aacute;s emblem&aacute;ticos distribuidos por toda la geograf&iacute;a espa&ntilde;ola.</li>
                              <li>Distribuir, comunicar y dise&ntilde;ar las estrategias de marketing necesarias para convertir la Red Nacional de Castillos y Palacios en&nbsp;<u>un referente de la MARCA ESPA&Ntilde;A</u>&nbsp;d&aacute;ndola a conocer por todo el mundo.</li>
                              <li>Cohesionar en una&nbsp;<strong>plataforma tecnol&oacute;gica www.spainheritagenetwork.com/com</strong>, el producto tur&iacute;stico de castillos y palacios para facilitar su distribuci&oacute;n a turoperadores, agencias de viaje, plataformas de distribuci&oacute;n y al cliente final facilit&aacute;ndole una herramienta innovadora para poder acceder a nuestros castillos y palacios m&aacute;s emblem&aacute;ticos.</li>
                              </ul>
                              <p>Partimos de castillos y palacios con al menos un cierto nivel de gesti&oacute;n activa (visitables, con experiencias tur&iacute;sticas variadas, de gesti&oacute;n p&uacute;blica o privada) y con un claro atractivo. Los castillos y palacios son un recurso con una identidad clara ligada a la historia de nuestra naci&oacute;n y por tanto a la MARCA ESPA&Ntilde;A, y con un gran potencial interpretativo para diferenciar cada castillo o palacio y cada destino con un relato propio y atractivo para la demanda.</p>
                              <p>Si conoce alg&uacute;n castillo o palacio de Espa&ntilde;a que pueda cumplir con las condiciones del club de producto y todav&iacute;a no est&aacute; en la red d&iacute;ganos cu&aacute;l a trav&eacute;s del correo de contacto y nos pondremos en contacto con &eacute;l.</p>
                              <p>Muchas gracias por su visita y esperamos que disfrute de la red de castillos y palacios de Espa&ntilde;a.</p>
                              <p>Para m&aacute;s informaci&oacute;n&nbsp;<a href="mailto:info@spainheritagenetwork.com"><u>info@spainheritagenetwork.com</u></a></p>
                              </div>
                              </div>                                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
    components:{
        DefaultLayout, 
        // IconPalace, 
        // IconRealPalace, 
        // IconArt, 
        // IconWalledEnclosure,
        // IconMonastery, 
        // Cards4,
        // CardsThumbnails// Cards
    },
    data(){
        return{
       
        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
        }
        .quines-main ul{
            padding-left: 15px;
        }
    }
</style>